<script>
export default {
    name: "VError",
};
</script>

<template>
    <div class="v-task-viewer">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="pb-mg">
                            <div class="nice-info">
                                <i class="fas fa-ban fa-4x text-danger"></i>

                                <h1 class="mt-4 text-danger">{{$t('acl.no_access')}}</h1>                                

                                <p class="mt-4">
                                    {{$t('acl.no_access_explanation_text')}}
                                </p>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>